import React from "react"
import Layout from "../hoc/Layout"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

const Essays = (props) => {

  const data = useStaticQuery(graphql`
    {
      allFile(filter: { extension: { eq: "png" } }) {
        edges {
          node {
            publicURL
            name
            childImageSharp {
              gatsbyImageData(
                width: 200
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `)



  return (
    <Layout location={ props.location } pageTitle="Essays">
      <h1>Essays</h1>
      <p>Long-form writings.</p>

      <StaticImage src="https://vli-portfolio.s3.us-east-2.amazonaws.com/garden/adult4.png" alt="" />

    </Layout>
  )
}

export default Essays
